.messages_detail_header_wrapper {
  height: 44px;
  font-weight: 500;
  font-size: 22px;
  line-height: 32px;
  box-shadow: inset 0px -1px 0px rgba(191, 191, 191, 0.25);
  padding: 16px 0px;
}

@media screen and (min-width: 1000px) {
	.messages_detail_header_wrapper {
		height: 51px;
		box-shadow: inset 0px -1px 0px rgba(191, 191, 191, 0.25);
		font-weight: 500;
		font-size: 24px;
		line-height: 35px;
		padding: 30px 40px 30px;
		position: relative;
	}
}

.messages_detail_header_wrapper > p {
  float: left;
  margin: 4px 0px;
}

@media screen and (min-width: 1000px) {
	.messages_detail_header_wrapper > p {
		width: 192px;
		margin: 8px 0px;
		float: left;
	}
}

.messages_detail_header_btns_wrapper {
  float: right;
}

@media screen and (min-width: 1000px) {
	.messages_detail_header_btns_wrapper {
		height: 44px;
		width: 172px;
		margin: 3.5px 0px;
		float: right;
	}
}

.messages_detail_body_wrapper {
  height: calc(100vh - 152px);
  padding-top: 32px;
  overflow-y: scroll;
  width: 100%;
}

.messages_detail_body {
		width: 100%;
		margin-left: 0px;
}

@media screen and (min-width: 1000px) {
	.messages_detail_body {
		width: 831px;
		margin-left: 65px;
	}
}

.messages_detail_content {
  margin-bottom: 20px;
}

.messages_detail_label_wrapper {
  margin-bottom: 10px;
	height: 24px;
}

.messages_detail_content_title {
  font-weight: bold;
	width: 100%;
	float: left;
}

.message_detail_content_liked {
	width: 120px;
	float: left;
	font-weight: normal;
}

.messages_detail_content_title_label {
	width: 100px;
	float: left;
}

.messages_detail_content_video {
	width: 100%;
	object-fit: contain;
}

.messages_detail_content_image {
	object-fit: contain;
}

.messages_detail_content_voice {
  width: 100%;
}

@media screen and (min-width: 1000px) {
	.messages_detail_content_voice {
		width: 50%;
		min-width: 100px;
	}
}
