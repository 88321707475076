.header_wrapper {
  grid-row: 1 / 2;
  font-size: 14px;
  line-height: 16px;
  width: 100%;
  height: 21px;
  margin-top: 12px;
  padding-top: 18px;
  padding-bottom: 18px;
}
.header_logo {
  width: 174px;
  height: 21px;
  margin: 0 auto;
}
.button_wrapper {
  grid-row: 2 / 3;
}
.menu_button {
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  text-align: left;
  cursor: pointer;
}
.menu_button:hover {
  background-color: #F2F2F2;
  border-radius: 4px;
}
.children_button {
  padding-left: 62px;
}
.footer_wrapper {
  grid-row: 3 / 4;
}

.menu_btn_wrapper {
  height: 44px;
  margin: 0 auto;
  padding-bottom: 16px;
}

.menu_button > img {
  display: block;
  float: left;
}

.menu_button_icon {
  margin-top: 12px;
  margin-bottom: 12px;
  display: block;
  float: left;
}

.menu_button_label {
  float: left;
  padding-top: 11px;
}

.menu_button_accordion_icon {
  width: 24px;
  height: 24px;
  float: left;
}

.menu_header_on_contributors {
  width: 216px;
  height: 60px;
}

.menu_header_inner_wrapper {
  width: 194px;
  height: 100%;
  margin: 0 auto;
}

.menu_header_inner_wrapper > img {
  height: 34px;
  width: 34px;
  display: block;
  float: left;
  object-fit: cover;
  border-radius: 4px;
  cursor: pointer;
}

.menu_header_inner_wrapper > p {
  margin: 4px 0px;
  padding: 5px;
  font-size: 14px;
  width: 116px;
  max-width: 116px;
  word-break: break-all;
  float: left;
  cursor: pointer;
}

.popup_trigger_img {
  height: 24px;
  width: 24px;
  display: block;
  float: right;
  cursor: pointer;
}

.popup_header {
  height: 20px;
  width: 100%;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
  text-align: center;
  margin: 20px 0px;
}
