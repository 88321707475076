@import url(https://fonts.googleapis.com/earlyaccess/notosansjp.css);
@import url(reset.css);

body {
  margin: 0;
  padding: 0;
  font-family: 'Noto Sans JP', sans-serif;
  outline: none;
}

p {
  margin: 0;
}

pre {
  white-space: pre-wrap;
}

button, input, select, textarea, pre {
  font-family : inherit;
}

.bm-burger-button {
  float: left;
  width: 24px;
  height: 24px;
  position: relative;
  margin: 0px 16px;
}

.bm-cross-button {
  height: 32px !important;
  width: 32px !important;
  margin-top: 18px;
  margin-right: 246px;
}

.bm-item-list {
  padding: 100px 0px;
  height: 540px !important;
}

.menu-item {
  font-size: 18px;
  padding: 18px 0px;
}

.bm-overlay {
  z-index: 1010 !important;
}

.bm-menu-wrap {
  background: rgba(255,255,255,1) !important;
}

#react-burger-menu-btn {
  top: 21px !important;
  width: 24px !important;
}

.p_ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rbc-timeslot-group {
  min-height: 60px !important;
}

.rbc-addons-dnd-resizable {
  display: flex !important;
}
