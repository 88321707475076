.analytics_wrapper_for_sp {
  height: calc(100vh - 48px);
  overflow-y: scroll;
}

.analytics_header {
  height: 51px;
  box-shadow: inset 0px -1px 0px rgba(191, 191, 191, 0.25);
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  padding: 30px 40px 30px;
}

.analytics_header_for_sp {
  width: 100%;
  font-weight: 500;
  font-size: 22px;
  line-height: 32px;
  box-shadow: inset 0px -1px 0px rgba(191, 191, 191, 0.25);
  padding: 16px 0px;
}

.analytics_header > p {
  width: 292px;
  margin: 4.5px 0px;
  float: left;
}

.analytics_header_for_sp > p {
  float: left;
  margin: 4px 0px;
}

.analytics_header_contributor_select_wrapper {
  width: 344px;
  float: right;
}

.analytics_header_contributor_select_wrapper_for_sp {
  width: 344px;
  margin: 18px 0px;
  float: right;
}

.analytics_body_wrapper {
  height: calc(100vh - 152px);
  width: 343px;
  padding-top: 32px;
}

.analytics_body_wrapper_for_sp {
  padding: 32px 0px;
  overflow-x: scroll;
}

.analytics_body {
  width: 831px;
  height: 100%;
  margin-left: 65px;
}

.analytics_body_for_sp {
  width: 100%;
  height: 100%;
}

.large_analytics_wrapper,
.small_analytics_row_wrapper,
.large_analytics_wrapper_for_sp,
.small_analytics_row_wrapper_for_sp {
  margin-bottom: 40px;
  max-width: 100%;
}

.large_analytics_label {
  width: 831px;
  box-shadow: inset 0px -1px 0px rgba(191, 191, 191, 0.25);
  font-weight: 500;
  font-size: 20px;
  line-height: 35px;
}

.large_analytics_label_for_sp {
  width: 100%;
  box-shadow: inset 0px -1px 0px rgba(191, 191, 191, 0.25);
  font-weight: 500;
  font-size: 20px;
  line-height: 35px;
}

.large_analytics_label > span,
.large_analytics_label_for_sp > span {
  font-size: 12px;
  color: #828282;
  line-height: 24px;
  display: block;
}

.small_analytics_row_wrapper_right {
  float: right;
}

.small_analytics_row_wrapper_left {
  float: left;
}

.small_analytics_label {
  width: 395px;
  margin-right: 20px;
  box-shadow: inset 0px -1px 0px rgba(191, 191, 191, 0.25);
  font-weight: 500;
  font-size: 20px;
  line-height: 35px;
}

.small_analytics_label > span {
  font-size: 12px;
  color: #828282;
  line-height: 24px;
  display: block;
}

.small_analytics_text_result {
  width: 395px;
  margin-right: 20px;
  font-weight: 500;
  font-size: 40px;
  line-height: 35px;
  text-align: right;
}

.small_analytics_text_result_for_sp {
  width: 100%;
  margin-right: 20px;
  font-weight: 500;
  font-size: 40px;
  line-height: 35px;
  text-align: right;
}

.small_analytics_text_result > p,
.small_analytics_text_result_for_sp > p {
  margin: 20px;
}

.small_analytics_text_result > p > span,
.small_analytics_text_result_for_sp > p > span {
  font-size: 20px;
}

