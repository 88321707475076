.modal_on_overlay {
  width: 373px;
  height: 158px;
  padding: 0px;
  border: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #FFFFFF;
}

.contents_header_wrapper {
  height: 51px;
  box-shadow: inset 0px -1px 0px rgba(191, 191, 191, 0.25);
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  padding: 30px 40px 30px;
}

.contents_header_wrapper_for_sp {
  width: 100%;
  height: 44px;
  font-weight: 500;
  font-size: 22px;
  line-height: 32px;
  box-shadow: inset 0px -1px 0px rgba(191, 191, 191, 0.25);
  padding: 16px 0px;
}

.contents_header_wrapper > p {
  margin: 8px 0px;
  float: left;
}

.account_body_wrapper {
  width: 450px;
  padding-top: 40px;
  padding-left: 57px;
}

.email_notification_toggle.react-toggle--checked .react-toggle-track {
  background-color: #13C6B5;
}

.email_notification_toggle.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #13C6B5;
}

.email_notification_toggle.react-toggle--checked .react-toggle-thumb {
  border-color: #13C6B5;
}

.modal_dialog {
  position: absolute;
  box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.1);
  background-color: #FFFFFF;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal_dialog_textarea {
  height: 112px;
  width: 343px;
  position: relative;
  font-size: 14px;
  line-height: 20px;
}

.modal_dialog_textarea > img {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 14px;
  left: 308px;
  cursor: pointer;
}

.modal_dialog_textarea > p {
  width: 311px;
  padding: 0px 16px;
  position: absolute;
  top: 48px;
  word-break: break-all;
}
