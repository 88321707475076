.messages {
  width: 100%;
  height: 94px;
  font-size: 14px;
  line-height: 20px;
}

.each_message {
  height: 94px;
}

.each_message:hover {
  background-color: #F9F9F9;
}

.each_message_for_sp {
  width: 100%;
  height: 94px;
}

.page-item {
  height: 50px;
  width: 50px;
  text-align: center;
}
.page-item-sp {
  height: 50px;
  width: 35px;
  text-align: center;
}
.page-link {
  width: 100%;
  height: 100%;
}
.pagination {
  padding: 0px;
}
.pagination > li {
  display: inline-block;
}
.pagination > li {
  list-style: none;
}
.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  line-height: 3;
  text-decoration: none;
  color: #000000;
  background-color: #ffffff;
  border: 1px solid rgb(242, 242, 242);
}
.pagination>li.active>a {
  color: #ffffff;
  background-color: rgb(130, 130, 130);
  border-color: rgb(130, 130, 130);
  border: 1px solid rgb(242, 242, 242);
}

/* Style the active class (and buttons on mouse-over) */
.pagination > li > a:hover {
  background-color: rgb(130, 130, 130);
  color: #ffffff;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}

.messages_list_header_wrapper {
  height: 51px;
  box-shadow: inset 0px -1px 0px rgba(191, 191, 191, 0.25);
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  padding: 30px 40px 30px;
  position: relative;
}

.messages_list_header_wrapper_for_sp {
  width: 100%;
  height: 44px;
  font-weight: 500;
  font-size: 22px;
  line-height: 32px;
  box-shadow: inset 0px -1px 0px rgba(191, 191, 191, 0.25);
  padding: 16px 0px;
}

.messages_list_header_wrapper > p {
  margin: 8px 0px;
  float: left;
}

.messages_list_header_wrapper_for_sp > p {
  float: left;
  margin: 4px 0px;
}

.messages_list_header_btns_wrapper {
  height: 44px;
  width: 172px;
  margin: 3.5px 0px;
  float: right;
}

.messages_list_header_btns_wrapper_for_sp {
  float: right;
}

.messages_list_message_toast_for_sp {
  position: absolute;
  top: 40px;
}

.messages_list_header_tab {
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  position: absolute;
  top: 84px;
  cursor: pointer;
}

.messages_list_body_wrapper {
  height: calc(100vh - 152px);
  padding-top: 32px;
  overflow-y: scroll;
}

.messages_list_body {
  width: 831px;
  margin-left: 65px;
}

.message_list_body_for_sp {
  height: calc(100vh - 147px);
  overflow-y: scroll;
}

.messsage_list_body_selectable_types_for_sp {
  width: 100%;
  height: 44px;
  padding: 10px 0px;
}

.messsage_list_body_selectable_types_for_sp > select {
  width: 100%;
  height: 44px;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 6px;
}

.messages_list_table_header {
  width: 100%;
  height: 41px;
  background-color: #F2F2F2;
  border-top: 1px solid #E0E0E0;
  border-bottom: 1px solid #E0E0E0;
  font-size: 14px;
  line-height: 20px;
}

.messages_list_table_header_column {
  height: 100%;
  float: left;
}

.messages_list_table_header_column > p {
  margin-top: 9px;
}

.messages_list_message_text_wrapper {
  width: 251px;
  height: 100%;
  float: left;
  font-size: 14px;
  line-height: 20px;
  text-decoration: underline;
}

.messages_list_message_text {
  width: 197px;
  height: 40px;
  margin-top: 27px;
  margin-left: 18px;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.messages_list_message_text > span {
  cursor: pointer;
}

.messages_list_message_media_wrapper {
  width: 160px;
  height: 100%;
  float: left;
}

.messages_list_message_image_wrapper {
  width: 54px;
  height: 54px;
  margin-top: 20px;
  cursor: pointer;
}

.messages_list_message_image_wrapper_for_sp {
  width: 54px;
  height: 54px;
  margin-top: 20px;
  float: left;
}

.messages_list_message_image_wrapper > img {
  width: 54px;
  height: 54px;
  object-fit: cover;
}

.messages_list_message_image_wrapper_for_sp > img {
  width: 54px;
  height: 54px;
  object-fit: cover;
}

.messages_list_message_voice_wrapper {
  width: 54px;
  height: 54px;
  margin-top: 20px;
  background-color: #BDBDBD;
  cursor: pointer;
}

.messages_list_message_voice_wrapper_for_sp {
  width: 54px;
  height: 54px;
  margin-top: 20px;
  background-color: #BDBDBD;
  float: left;
}

.messages_list_message_voice_wrapper > img {
  width: 24px;
  height: 24px;
  margin-top: 15px;
  margin-left: 15px;
}

.messages_list_message_voice_wrapper_for_sp > img {
  width: 24px;
  height: 24px;
  margin-top: 15px;
  margin-left: 15px;
}

.messages_list_message_video_wrapper {
  width: 54px;
  height: 54px;
  margin-top: 20px;
  position: relative;
  cursor: pointer;
}

.messages_list_message_video_thumbnail_base {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
}

.messages_list_message_video_thumbnail_shadow {
  width: 100%;
  height: 50%;
  position: absolute;
  top: 27px;
}

.messages_list_message_video_thumbnail_icon {
  width: 14px;
  height: 10px;
  position: absolute;
  top: 38.64px;
  left: 37.33px;
}


.messages_list_message_video_wrapper_for_sp {
  width: 54px;
  height: 54px;
  margin-top: 20px;
  float: left;
  position: relative;
}

.messages_list_message_video_thumbnail_base_for_sp {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
}

.messages_list_message_video_thumbnail_shadow_for_sp {
  width: 100%;
  height: 50%;
  position: absolute;
  top: 27px;
}

.messages_list_message_video_thumbnail_icon_for_sp {
  width: 14px;
  height: 10px;
  position: absolute;
  top: 38.64px;
  left: 37.33px;
}

.messages_list_message_no_media {
  width: 54px;
  height: 20px;
  margin-top: 20px;
  padding: 17px 0px;
}

.messages_list_message_no_media_for_sp {
  width: 54px;
  height: 20px;
  margin-top: 20px;
  padding: 17px 0px;
  float: left;
  text-align: center;
}

.messages_list_liked_wrapper {
  width: 100px;
  height: 100%;
  float: left;
}

.message_list_liked {
  width: 77px;
  word-break: break-all;
  margin-top: 37px;
}

.messages_list_created_at_wrapper,
.messages_list_updated_at_wrapper {
  width: 120px;
  height: 100%;
  float: left;
}

.messages_list_created_at,
.messages_list_updated_at {
  width: 77px;
  word-break: break-all;
  margin-top: 25px;
}

.messages_list_created_at > p,
.messages_list_updated_at > p {
  margin-top: 9px;
}

.messages_list_popup_wrapper {
  width: 80px;
  height: 100%;
  float: left;
  text-align: right;
}

.messages_list_popup_wrapper_for_sp {
  width: 64px;
  height: 100%;
  float: left;
  text-align: right;
}

.messages_list_pagination_wrapper {
  width: 500px;
  margin: 0 auto;
}

.messages_list_pagination_wrapper_for_sp {
  width: 315px;
  margin: 0 auto;
  text-align: center;
}

.messages_list_each_message_label_for_sp {
  width: 225px;
  float: left;
  height: 57px;
  padding: 18.5px 0px;
}

.messages_list_each_message_label_text_for_sp {
  width: 200px;
  font-size: 16px;
  line-height: 23px;
  text-decoration-line: underline;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.messages_list_each_message_label_text_for_sp > span {
  cursor: pointer;
}

.messages_list_each_message_label_created_at_for_sp,
.messages_list_each_message_label_liked_for_sp {
  width: 100%;
  font-size: 12px;
  line-height: 17px;
  color: #828282;
}

.messages_list_each_message_label_created_at_for_sp > p,
.messages_list_each_message_label_liked_for_sp > p {
  width: 100%;
}

.messages_new_header_wrapper {
  height: 51px;
  box-shadow: inset 0px -1px 0px rgba(191, 191, 191, 0.25);
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  padding: 30px 40px 30px;
}

.messages_new_header_wrapper_for_sp {
  width: 100%;
  height: 44px;
  font-weight: 500;
  font-size: 22px;
  line-height: 32px;
  box-shadow: inset 0px -1px 0px rgba(191, 191, 191, 0.25);
  padding: 16px 0px;
}

.messages_new_header_wrapper > p {
  width: 216px;
  margin: 8px 0px;
  float: left;
}

.messages_new_header_wrapper_for_sp > p {
  float: left;
  margin: 4px 0px;
}

.messages_new_header_btns_wrapper {
  height: 44px;
  width: 344px;
  margin: 3.5px 0px;
  float: right;
}

.messages_new_header_btns_wrapper_for_sp {
  float: right;
}

.messages_new_header_btn {
  height: 44px;
  float: left;
}

.messages_new_body_wrapper {
  height: calc(100vh - 152px);
  padding-top: 32px;
  overflow-y: scroll;
}

.messages_new_body {
  width: 704px;
  margin-left: 116px;
}

.messages_new_configuration_wrapper {
  width: 100%;
  height: 100px;
}

.messages_new_configuration_send_at_label {
  width: 120px;
  float: left;
}

.messages_new_configuration_send_at_label_for_sp {
  padding: 20px 10px;
}

.messages_new_configuration_send_at_input_wrapper {
  width: 584px;
  float: left;
}

.messages_new_configuration_send_at_input_wrapper_for_sp {
  padding: 0px 10px;
}

.messages_new_configuration_send_at_input {
  width: 100%;
  margin-bottom: 16px;
}

.messages_new_configuration_send_at_input_for_sp {
  width: 100%;
  padding: 10px 0px;
}

.messages_new_configuration_radio_button {
  margin: 3px 16px 3px 0px;
  float: left;
}

.messages_new_configuration_radio_button_for_sp {
  margin-right: 6px;
  float: left;
}

.messages_new_configuration_radio_button_disabled,
.messages_new_configuration_radio_button_disabled_for_sp {
  width: 18px;
  height: 18px;
  border-radius: 100%;
  background-color: #E0E0E0;
}

.messages_new_configuration_radio_button_enabled_outer,
.messages_new_configuration_radio_button_enabled_outer_for_sp {
  width: 18px;
  height: 18px;
  border-radius: 100%;
  background-color: #17DBC9;
  position: relative;
}

.messages_new_configuration_radio_button_enabled_inner,
.messages_new_configuration_radio_button_enabled_inner_for_sp {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: #FFFFFF;
  position: absolute;
  top: 5px;
  left: 5px;
}

.messages_new_configuration_send_at_input_label {
  float: left;
  font-size: 14px;
  line-height: 24px;
}

.messages_new_configuration_send_at_input_label_for_sp {
  float: left;
  font-size: 14px;
  line-height: 20px;
}

.messages_new_configuration_send_at_calendar_input_wrapper {
  float: left;
}

.messages_new_configuration_send_at_calendar_input_wrapper_for_sp {
  float: left;
  width: calc(100% - 24px);
}

.messages_new_configuration_send_at_calendar_input_wrapper > input {
  width: 160px;
  height: 42px;
  padding: 0px 12px;
  border: 1px solid #E0E0E0;
  border-radius: 4px;
}

.messages_new_configuration_send_at_calendar_input_wrapper_for_sp > input {
  width: calc(100% - 26px);
  height: 42px;
  padding: 0px 12px;
  border: 1px solid #E0E0E0;
  border-radius: 4px;
}

.messages_new_configuration_send_at_calender {
  position: absolute;
  z-index: 1;
}

.messages_new_configuration_send_at_time_select_wrapper {
  float: left;
  width: 104px;
}

.messages_new_configuration_send_at_time_select_wrapper_for_sp {
  float: left;
  width: 130px;
}

.messages_new_configuration_send_at_time_colon,
.messages_new_configuration_send_at_time_colon_for_sp {
  float: left;
  width: 10px;
  margin: 10px;
}

.messages_new_component_thread {
  height: 22px;
  border: 2px solid #BDBDBD;
}

.messages_new_component_thread_for_sp {
  height: 22px;
  border: 2px solid #BDBDBD;
}

.messages_new_add_component_btn_wrapper,
.messages_new_add_component_btn_wrapper_for_sp {
  width: 100%;
  height: 40px;
  position: relative;
}

.messages_new_add_component_btn_wrapper > button,
.messages_new_add_component_btn_wrapper_for_sp > button {
  width: 100%;
  height: 100%;
  border: 1px solid #E0E0E0;
  background-color: #FBFBFB;
  color: #13C6B5;
  font-size: 12px;
  line-height: 17px;
  font-weight: 500;
  cursor: pointer;
}

.messages_new_add_component_btn_label_wrapper,
.messages_new_add_component_btn_label_wrapper_for_sp {
  width: 180px;
  margin: 0 auto;
}

.messages_new_add_component_btn_label_wrapper > img,
.messages_new_add_component_btn_label_wrapper_for_sp > img,
.messages_new_add_component_btn_label_wrapper > svg,
.messages_new_add_component_btn_label_wrapper_for_sp > svg {
  width: 20px;
  height: 20px;
  display: block;
  float: left;
  margin-top: 8px;
  margin-right: 4px;
  margin-bottom: 8px;
}

.messages_new_add_component_btn_label_wrapper > span,
.messages_new_add_component_btn_label_wrapper_for_sp > span {
  margin-top: 9.5px;
  margin-bottom: 9.5px;
  display: block;
  float: left;
}

.message_share_modal_btns_wrapper {
  width: calc(100% - 32px);
  padding: 20px 16px;
}

.message_share_modal_btn_twitter {
  width: 100%;
  height: 40px;
  background-color: #1DA1F2;
  border: none;
  border-radius: 4px;
  color: #FFFFFF;
  cursor: pointer;
}

.message_share_btn_twitter {
  width: 88px;
  height: 24px;
  background-color: #1DA1F2;
  border: none;
  border-radius: 4px;
  color: #FFFFFF;
  cursor: pointer;
}

.twitter_btn_icon {
	width: 18px;
	height: 18px;
	padding: 2px;
	float: left;
}

.twitter_btn_label {
	float: right;
	line-height: 22px;
}
