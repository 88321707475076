.modal_overlay {
  width: 100vw;
  height: 100vh;
  transition: opacity 500ms ease-in-out;
  z-index: 10000;
  position: absolute;
  background-color: rgba(0,0,0,0.3);
  inset: 0px;
}

.header_menu_for_sp {
  width: 56px;
  height: 24px;
}

.header_contributor_select_for_sp {
  width: 160px;
  height: 36px;
  border: 1px solid #BDBDBD;
  box-sizing: border-box;
  border-radius: 4px;
  float: left;
  margin: 6px 0px 6px 16px;
  padding: 6px 8px;
}

.header_menu_hamburger_for_sp {
  float: right;
}

.header_contributor_select_label_wrapper_for_sp {
  width: 144px;
  height: 24px;
  font-size: 12px;
  line-height: 17px;
  cursor: pointer;
}

.header_contributor_icon_for_sp {
  width: 20px;
  height: 20px;
  float: left;
  margin: 2px 0px;
  object-fit: cover;
  border-radius: 2px;
  cursor: pointer;
}

.header_selection_icon_for_sp {
  width: 24px;
  height: 24px;
  float: right;
}

.header_contributor_select_label_wrapper_for_sp > p {
  width: 92px;
  float: left;
  margin: 0px;
  padding: 3.5px 4px;
}

.hamburger_icon_top {
  border: 1px solid #333333;
  width: 24px;
  position: absolute;
  top: 21px;
  border-radius: 10px;
}

.hamburger_icon_middle {
  border: 1px solid #333333;
  width: 24px;
  position: absolute;
  top: 27px;
  border-radius: 10px;
}

.hamburger_icon_bottom {
  border: 1px solid #333333;
  width: 24px;
  position: absolute;
  top: 33px;
  border-radius: 10px;
}

.hamburger_menus_wrapper {
  width: 100%;
  margin-bottom: 80px;
}

.hamburger_menus_header_on_contributor_page {
  width: 222px;
  height: 80px;
  margin: 0 auto;
}

.hamburger_menus_header_on_contributor_page > img {
  height: 48px;
  width: 48px;
  display: block;
  float: left;
  border-radius: 10px;
  object-fit: cover;
}

.hamburger_menus_header_on_contributor_page > p {
  margin: 8px 0px;
  padding: 5px;
  font-size: 16px;
  line-height: 22px;
  width: 116px;
  max-width: 116px;
  word-break: break-all;
  float: left;
}

.popup_trigger_img {
  height: 48px;
  width: 48px;
  display: block;
  float: right;
  cursor: pointer;
}

.popup_header {
  height: 20px;
  width: 100%;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
  text-align: center;
  margin: 20px 0px;
}

.popup_body {
  height: 300px;
  width: 100%;
}

.popup_contributor_row {
  padding: 13px 0px;
}

.popup_contributor_label_wrapper {
  margin: 0 auto;
  width: 181px;
  height: 34px;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
}

.popup_contributor_label_wrapper > img {
  width: 34px;
  height: 34px;
  display: block;
  float: left;
  object-fit: cover;
  border-radius: 4px;
}

.popup_contributor_label_wrapper > p {
  margin: 7px 0px 7px 12px;
  float: left;
  max-width: 135px;
  word-break: break-all;
}

.popup_footer_border {
  width: 100%;
  border: 1px solid #E0E0E0;
}

.popup_footer_wrapper {
  padding: 20px 0px;
}

.popup_footer_label_wrapper {
  width: 151px;
  height: 24px;
  font-size: 12px;
  line-height: 18px;
  color: #4F4F4F;
  margin: 0 auto;
}

.popup_footer_label_wrapper > p {
  margin: 3px 0px;
  float: left;
}

.popup_footer_label_wrapper > img {
  height: 24px;
  width: 24px;
  display: block;
  float: right;
  cursor: pointer;
}

.hamburger_menus_header {
  margin: 0 auto;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  width: 200px;
  height: 80px;
}

.hamburger_menus_header > img {
  width: 120px;
  display: block;
  float: left;
}

.hamburger_menus_header > span {
  margin: 4px 0px 4px 5px;
  float: left;
}

.button_module {
  font: normal bold 12px 'Noto Sans JP', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 6px;
  border-radius: 10px;
  cursor: pointer;
}

.button_module_content_wrapper {
  width: 82px;
  margin: 0 auto;
}

.button_module_content_wrapper > img {
  width: 20px;
  height: 20px;
  display: block;
  float: left;
  margin-top: 8px;
  margin-right: 6px;
}

.button_module_content_icon_label {
  margin-top: 9px;
  margin-bottom: 12px;
  display: block;
  float: left;
}

.color_picker_module_input_wrapper {
  height: 44px;
  margin-top: 16px;
  margin-bottom: 16px;
}

.color_picker_module_input_wrapper > label {
  width: 126px;
  display: block;
  float: left;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

.color_picker_module_input_wrapper > input {
  height: 44px;
  padding-left: 12px;
  padding-right: 12px;
  border: 1px solid #E0E0E0;
  font-size: 14px;
  line-height: 20px;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  float: left;
}

.color_picker_module_color_sample {
  width: 44px;
  height: 44px;
  border-radius: 100%;
  margin: 0px 12px;
  float: left;
}

.color_picker_module_color_list_wrapper {
  width: 100%;
}

.color_picker_module_color_list_item {
  height: 40px;
  width: 100px;
  border-radius: 10px;
  margin: 8px;
  float: left;
  font-size: 14px;
  color: #FFFFFF;
  cursor: pointer;
}

.input_module {
  margin-top: 16px;
  margin-bottom: 16px;
}

.input_module_label {
  width: 126px;
  display: block;
  float: left;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

.input_module_custom_label_for_sp {
  font-size: 12px;
  line-height: 145.8%;
}

.input_module_empty_label {
  float: left;
  width: 126px;
}

.input_module_textarea {
  display: block;
  float: left;
  height: 152px;
  padding-left: 12px;
  padding-right: 12px;
  color: #333333;
  font-size: 14px;
  line-height: 20px;
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  box-sizing: border-box;
  resize: none;
}

.input_module_text_input {
  display: block;
  float: left;
  height: 44px;
  padding-left: 12px;
  padding-right: 12px;
  color: #333333;
  font-size: 14px;
  line-height: 20px;
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  box-sizing: border-box;
}

.input_module_word_count {
  display: block;
  float: left;
  width: 50px;
  margin: 0px 0px 0px 5px;
  line-height: 40px;
  color: #828282;
  font-size: 14px;
  letter-spacing: 0.016em;
}

.input_module_error_text {
  font-size: 12px;
  line-height: 17px;
  color: #E25047;
}

.input_module_custom_label {
  margin-left: 150px;
  font-size: 12px;
  line-height: 145.8%;
}

.message_module {
  height: 51px;
  font-size: 12px;
  line-height: 14px;
  float: right;
  border-radius: 4px;
  box-shadow: 0px 5px 7px rgba(3, 151, 137, 0.1);
}

.message_module_text_wrapper {
  width: 281px;
  height: 27px;
  margin: 12px 16px;
  position: relative;
}

.message_module_text_wrapper > p {
  position: absolute;
  top: 23.53%;
}

.pagination_module {
  width: 831px;
  height: 52px;
  padding: 20px 0px;
}

.pagination_module_previous {
  width: 50px;
  height: 24px;
  border: 1px solid #F2F2F2;
  float: left;
  text-align: center;
  padding: 13px 0px;
  cursor: pointer;
}

.pagination_module_page_number_box {
  width: 50px;
  height: 24px;
  border: 1px solid #F2F2F2;
  float: left;
  text-align: center;
  padding: 13px 0px;
  cursor: pointer;
}

.pagination_module_next {
  width: 50px;
  height: 24px;
  border: 1px solid #F2F2F2;
  float: left;
  text-align: center;
  padding: 13px 0px;
  cursor: pointer;
}

.select_module {
  height: 44px;
}

.select_module > label {
  width: 126px;
  display: block;
  float: left;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

.select_module > select {
  display: block;
  float: left;
  height: 44px;
  padding-left: 12px;
  padding-right: 12px;
  color: #333333;
  font-size: 14px;
  line-height: 20px;
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  box-sizing: border-box;
}

.message_form_module {
  width: 100%;
  position: relative;
}

.message_form_module_content_wrapper {
  width: 100%;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  font-size: 12px;
  line-height: 17px;
}

.message_form_module_media_wrapper {
  width: 100%;
  height: 70px;
  background-color: #FBFBFB;
}

.message_form_module_text_wrapper {
  width: 67px;
  height: 50px;
  margin: 10px 20px;
  float: left;
  background-color: #F2F2F2;
  border-radius: 3px;
  text-align: center;
  cursor: pointer;
}

.message_form_module_av_wrapper {
  width: 67px;
  height: 50px;
  margin: 10px 20px;
  float: left;
  border-radius: 3px;
  text-align: center;
  cursor: pointer;
}

.message_form_module_close_btn_wrapper {
  margin: 23px 21px;
  float: right;
}

.message_form_module_close_btn_wrapper > img {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.message_form_module_error_wrapper {
  padding-top: 12px;
}

.message_form_module_error {
  width: calc(100% - 44px);
  background-color: #FDF1F1;
  color: #E25047;
  margin: 0 auto;
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  padding: 12px 0px;
  text-align: center;
  border-radius: 10px;
}

.message_form_module_textarea {
  width: calc(100% - 44px);
  height: 204px;
  margin: 12px 22px 0px 22px;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  resize: none;
}

.message_form_module_variable_wrapper {
  width: 84px;
  height: 20px;
  background-color: #E0E0E0;
  color: #4F4F4F;
  border-radius: 4px;
  font-size: 12px;
  line-height: 20px;
  font-weight: bold;
  text-align: center;
  letter-spacing: -0.016em;
  padding: 4px 0px;
  cursor: pointer;
}

.message_form_module_dropzone_area {
  width: calc(100% - 44px);
  height: 214px;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  text-align: center;
  margin: 12px 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.message_form_module_dropzone_area_label {
  color: #13C6B5;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

.message_form_module_dropzone_area_sublabel {
  color: #828282;
  font-size: 12px;
  line-height: 17px;
}

.message_form_module_uploaded_area_wrapper {
  height: 214px;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  text-align: center;
  margin: 12px 22px;
}

.remove_btn_wrapper {
  width: 100%;
  height: 32px;
  display: flex;
  justify-content: flex-end;

}

.message_form_module_remove_uploaded_btn_wrapper {
  width: 24px;
  height: 24px;
  padding: 4px;
  background-color: #828282;
  cursor: pointer;
}

.message_form_module_remove_uploaded_btn_wrapper > img {
  width: 24px;
  height: 24px;
}

.uploaded_file {
  width: 100%;
  padding: 40px 0px;
  display: flex;
  justify-content: center;
}

.message_form_module_uploaded_icon {
  width: 54px;
  height: 54px;
  display: block;
  float: left;
}

.message_form_module_uploaded_file_detail_wrapper {
  float: left;
  text-align: left;
  font-size: 14px;
  line-height: 20px;
  word-break: break-all;
}

.message_form_module_uploaded_file_name {
  margin-bottom: 8px;
  color: #333333;
}

.message_form_module_uploaded_file_size {
  color: #828282;
}

.modal_module_trigger_img {
  width: 18px;
  height: 5px;
  margin-top: 45px;
  margin-right: 20px;
  cursor: pointer;
}

.modal_module_inner_trigger_img_wrapper {
  width: 100%;
  height: 40px;
  border: none;
  background-color: transparent;
  color: #E25047;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  outline: none;
}

.modal_module_inner_trigger_label_wrapper {
  width: 82px;
  height: 20px;
  margin: 0 auto;
  padding: 9px 0px;
}

.modal_module_inner_trigger_label_wrapper > img {
  width: 18px;
  height: 20px;
  float: left;
}

.modal_module_inner_trigger_img_wrapper > span {
  float: left;
}

.modal_module_inner_popup_content_wrapper {
  box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.1);
}

.modal_module_inner_popup_content_text_wrapper {
  height: 112px;
  width: 373px;
  position: relative;
  font-size: 14px;
  line-height: 20px;
}

.modal_module_inner_popup_content_text_wrapper > img {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 14px;
  left: 347px;
  cursor: pointer;
}

.modal_module_inner_popup_content_text_wrapper > p {
  width: 341px;
  position: absolute;
  top: 48px;
  margin: 0px 16px;
}

.modal_module_inner_popup_content_btn_wrapper {
  height: 46px;
}

.modal_module_inner_popup_content_cancel_btn {
  width: 50%;
  height: 100%;
  background-color: #FFFFFF;
  float: left;
  position: relative;
  border-top: 1px solid #F2F2F2;
  color: #828282;
  font-size: 13px;
  line-height: 19px;
  cursor: pointer;
}

.modal_module_inner_popup_content_cancel_btn > p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal_module_inner_popup_content_execute_btn {
  width: 50%;
  height: 100%;
  background-color: #E25047;
  float: left;
  position: relative;
  border-top: 1px solid #E25047;
  color: #FFFFFF;
  font-size: 13px;
  line-height: 19px;
  cursor: pointer;
}

.modal_module_inner_popup_content_execute_btn > p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
