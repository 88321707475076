.account_header_wrapper {
  height: 51px;
  box-shadow: inset 0px -1px 0px rgba(191, 191, 191, 0.25);
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  padding: 30px 40px 30px;
  position: relative;
}

.account_header_wrapper_for_sp {
  width: 100%;
  height: 44px;
  font-weight: 500;
  font-size: 22px;
  line-height: 32px;
  box-shadow: inset 0px -1px 0px rgba(191, 191, 191, 0.25);
  padding: 16px 0px;
}

.account_header_wrapper > p {
  width: 192px;
  margin: 8px 0px;
  float: left;
}

.account_header_wrapper_for_sp > p {
  float: left;
  margin: 4px 0px;
}

.account_toast_wrapper_for_sp {
  position: absolute;
  top: 40px;
}

.account_body_wrapper {
  width: 450px;
  padding-top: 40px;
  padding-left: 57px;
}

.account_email_edit_wrapper,
.account_password_edit_wrapper {
  padding-top: 16px;
  padding-bottom: 16px;
}

.account_email_edit_wrapper_for_sp,
.account_password_edit_wrapper_for_sp {
  padding: 16px 0px;
}

.account_email_view_wrapper,
.account_password_view_wrapper {
  height: 40px;
  padding-top: 16px;
  padding-bottom: 16px;
}

.account_email_view_wrapper_for_sp {
  height: 40px;
  padding: 16px 0px;
}

.account_email_view_wrapper > label,
.account_password_edit_wrapper > label,
.account_password_view_wrapper > label,
.account_product_id_wrapper > label,
.account_approval_wrapper > label,
.account_censorship_wrapper > label {
  width: 126px;
  text-align: right;
  display: block;
  float: left;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

.account_email_edit_wrapper_for_sp > label,
.account_password_edit_wrapper_for_sp > label {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

.account_email_view_wrapper_for_sp > label {
  width: 100%;
  text-align: left;
  display: block;
  float: left;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

.account_email_view_value {
  display: block;
  float: left;
  margin-left: 24px;
  height: 44px;
  color: #333333;
  font-size: 14px;
  line-height: 20px;
}

.account_email_view_value_for_sp {
  display: block;
  float: left;
  color: #333333;
  font-size: 14px;
  line-height: 20px;
}

.account_email_editable_btn,
.account_email_editable_btn_for_sp,
.account_password_editable_btn,
.account_password_editable_btn_for_sp,
.account_approval_editable_btn,
.account_approval_editable_btn_for_sp,
.account_censorship_editable_btn,
.account_censorship_editable_btn_for_sp {
  display: block;
  float: left;
  margin-left: 24px;
  padding-left: 12px;
  padding-right: 12px;
  color: #13C6B5;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
}

.account_password_current_label {
  margin-left: 24px;
  float: left;
  font-size: 14px;
  line-height: 20px;
  color: #828282;
}

.account_password_current_label_for_sp,
.account_password_new_label_for_sp {
  font-size: 14px;
  line-height: 20px;
  color: #828282;
}

.account_password_current_input,
.account_password_new_input {
  margin-left: 126px;
}

.account_password_new_label {
  margin-left: 150px;
  font-size: 14px;
  line-height: 20px;
  color: #828282;
}

.account_password_view_wrapper_for_sp {
  height: 40px;
  padding: 16px 0px;
}

.account_password_view_wrapper_for_sp > label {
  width: 100%;
  text-align: left;
  display: block;
  float: left;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

.account_password_view_value {
  display: block;
  float: left;
  margin-left: 24px;
  height: 44px;
  color: #333333;
  font-size: 14px;
  line-height: 20px;
}

.account_password_view_value_for_sp {
  display: block;
  float: left;
  color: #333333;
  font-size: 14px;
  line-height: 20px;
}

.account_product_id_wrapper {
  padding-top: 16px;
  padding-bottom: 16px;
  height: 40px;
}

.account_product_id_wrapper_for_sp {
  padding: 16px 0px;
}

.account_product_id_wrapper_for_sp > label {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

.account_product_id_value {
  margin-left: 24px;
  float: left;
  font-size: 14px;
  line-height: 20px;
}

.account_product_id_value_for_sp {
  font-size: 14px;
  line-height: 20px;
}

.account_approval_wrapper,
.account_censorship_wrapper {
  padding-top: 16px;
  padding-bottom: 16px;
  height: 40px;
}

.account_censorship_wrapper_for_sp,
.account_approval_wrapper_for_sp {
  height: 40px;
  padding: 16px 0px;
}

.account_censorship_wrapper_for_sp > label,
.account_approval_wrapper_for_sp > label {
  width: 100%;
  text-align: left;
  display: block;
  float: left;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

.account_censorship_value_for_sp,
.account_approval_value_for_sp {
  display: block;
  float: left;
  height: 44px;
  color: #333333;
  font-size: 14px;
  line-height: 20px;
}

.account_approval_edit_wrapper,
.account_censorship_edit_wrapper {
  margin: 20px 0px 20px 150px;
  float: left;
  font-size: 14px;
  line-height: 20px;
}

.account_approval_value,
.account_censorship_value {
  margin-left: 24px;
  float: left;
  font-size: 14px;
  line-height: 20px;
}

.account_approval_edit_row,
.account_censorship_edit_row {
  width: 100%;
  margin-bottom: 16px;
}

.account_approval_edit_row_for_sp,
.account_censorship_edit_row_for_sp {
  width: 100%;
  margin-bottom: 16px;
}

.account_approval_edit_radio_btn_wrapper,
.account_censorship_edit_radio_btn_wrapper {
  margin-right: 16px;
  float: left;
}

.account_approval_edit_radio_btn_wrapper_for_sp,
.account_censorship_edit_radio_btn_wrapper_for_sp {
  margin-right: 16px;
  float: left;
}

.account_approval_edit_radio_btn_disabled,
.account_censorship_edit_radio_btn_disabled {
  width: 18px;
  height: 18px;
  border-radius: 100%;
  background-color: #E0E0E0;
}

.account_approval_edit_radio_btn_disabled_for_sp,
.account_censorship_edit_radio_btn_disabled_for_sp {
  width: 18px;
  height: 18px;
  border-radius: 100%;
  background-color: #E0E0E0;
}

.account_approval_edit_radio_btn_enabled_outer,
.account_censorship_edit_radio_btn_enabled_outer {
  width: 18px;
  height: 18px;
  border-radius: 100%;
  background-color: #17DBC9;
  position: relative;
}

.account_approval_edit_radio_btn_enabled_outer_for_sp,
.account_censorship_edit_radio_btn_enabled_outer_for_sp {
  width: 18px;
  height: 18px;
  border-radius: 100%;
  background-color: #17DBC9;
  position: relative;
}

.account_approval_edit_radio_btn_enabled_inner,
.account_censorship_edit_radio_btn_enabled_inner {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: #FFFFFF;
  position: absolute;
  top: 5px;
  left: 5px;
}

.account_approval_edit_radio_btn_enabled_inner_for_sp,
.account_censorship_edit_radio_btn_enabled_inner_for_sp {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: #FFFFFF;
  position: absolute;
  top: 5px;
  left: 5px;
}

.account_approval_edit_radio_btn_label,
.account_censorship_edit_radio_btn_label {
  float: left;
  font-size: 14px;
  line-height: 20px;
}

.account_approval_edit_radio_btn_label_for_sp,
.account_censorship_edit_radio_btn_label_for_sp {
  float: left;
  font-size: 14px;
  line-height: 20px;
}

.account_approval_edit_btns_wrapper,
.account_censorship_edit_btns_wrapper {
  margin: 20px 0px;
}

.account_approval_edit_btns_wrapper_for_sp,
.account_censorship_edit_btns_wrapper_for_sp {
  margin: 20px 0px;
}
