.maintenance_toast {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 288px;
  padding: 16px;
  background-color: #FDF1F1;
  color: #E25047;
  z-index: 1;
  border-radius: 10px;
}

.maintenance_heading {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.maintenance_heading > p {
  font: normal 900 14px 'Noto Sans JP', sans-serif;
}

.maintenance_heading > img {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.maintenance_toast > p {
  font: normal 500 12px 'Noto Sans JP', sans-serif;
}

.app_body {
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-columns: 216px 1fr;
}

.app_side_pane {
  grid-column: 1 / 2;
  background-color: #f9f9f9;
  display: grid;
  grid-template-rows: 128px 1fr 60px;
  box-shadow: 0 4px 8px rgb(0 0 0 / 3%);
}

.app_main {
  grid-column: 2 / 3;
  background-color: #ffffff;
}

.sp_app_body {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
}

.sp_app_header {
  width: 100%;
  height: 48px;
  background-color: #FFFFFF;
}

.sp_app_main {
  width: 100%;
  height: calc(100vh - 48px);
  background-color: #FFFFFF;
}

.onboarding_cautions_main {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.onboarding_cautions_section {
  border: 1px solid black;
  border-radius: 10px;
  padding: 8px;
  max-width: 320px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.onboarding_cautions_heading {
  font-size: 18px;
}

.onboarding_cautions_text {
  font-size: 14px;
  color: #E25047;
}
